import $ from "jquery";
import { logFactory, isCompExists, vendors } from "GlobalSite";

const compName = "comp_aem_hp-card-comparator";
const Log = logFactory(compName);
const { log, error } = Log;
const {
  splide: { Splide, fr },
} = vendors;

log("--->");

$(function load() {
  if (!isCompExists(compName, Log)) {
    return;
  }

  const $component = $(".hp-comparator");

  // accessible tabs from : https://www.w3.org/WAI/ARIA/apg/patterns/tabs/examples/tabs-manual/
  const $tabsContainer = $(".hp-comparator__tabs");
  const $tabs = $tabsContainer.find(".hp-comparator__tab");
  const $panels = $(".hp-comparator__panel");

  const $firstTab = $tabs.first();
  const $lastTab = $tabs.last();

  function setSelectedTab($currentTab) {
    const currentPanelId = $($currentTab).attr("aria-controls");

    $tabs
      .not($currentTab)
      .attr("aria-selected", "false")
      .attr("tabIndex", "-1");
    $panels.not(`#${currentPanelId}`).addClass("is-hidden");

    $currentTab.attr("aria-selected", "true").removeAttr("tabindex");
    $(`#${currentPanelId}`).removeClass("is-hidden");
  }

  function moveFocusToTab($currentTab) {
    $currentTab.trigger("focus");
  }

  function moveFocusToPreviousTab($currentTab) {
    if ($currentTab.is($firstTab)) {
      moveFocusToTab($lastTab);
    } else {
      const index = $tabs.index($currentTab);
      moveFocusToTab($($tabs.get(index - 1)));
    }
  }

  function moveFocusToNextTab($currentTab) {
    if ($currentTab.is($lastTab)) {
      moveFocusToTab($firstTab);
    } else {
      const index = $tabs.index($currentTab);
      moveFocusToTab($($tabs.get(index + 1)));
    }
  }

  $tabsContainer.on("click", ".hp-comparator__tab", function () {
    setSelectedTab($(this));
  });

  //keyboard Event
  $tabsContainer.on("keydown", ".hp-comparator__tab", (event) => {
    const tgt = event.currentTarget;
    let flag = false;

    switch (event.key) {
      case "ArrowLeft":
        moveFocusToPreviousTab($(tgt));
        flag = true;
        break;

      case "ArrowRight":
        moveFocusToNextTab($(tgt));
        flag = true;
        break;

      case "Home":
        moveFocusToTab($firstTab);
        flag = true;
        break;

      case "End":
        moveFocusToTab($lastTab);
        flag = true;
        break;

      default:
        break;
    }

    if (flag) {
      event.stopPropagation();
      event.preventDefault();
    }
  });

  // mobile carousel initialisation
  const mobileSliderOption = {
    gap: "1rem",
    mediaQuery: "min",
    breakpoints: {
      768: {
        destroy: true,
      },
    },
    i18n: fr,
  };

  const $sliders = $component.find(".splide");

  for (const slider of $sliders) {
    new Splide(slider, mobileSliderOption).mount();
  }
});
